import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { SmallRoundedSquare } from './shapes/RoundedSquare';
import { BackgroundBase } from './BackgroundBase';

const CrossingLine = styled.div`
  position: absolute;
  width: 80px;
  height: 1600px;
  right: 420px;
  top: -540px;
  background: ${({ theme }) => theme.colorsNew.product.regular};
  opacity: 0.1;
  transform: rotate(60deg);

  @media (min-width: ${MEDIUM}) {
    width: 500px;
    top: 160px;
    right: 240px;
  }
`;

const LightSmallSquare = styled(SmallRoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.product.light30,
  size: '42px',
  rotation: '-30deg',
})`
  position: absolute;
  right: 10%;
  bottom: 80vw;

  @media (min-width: ${MEDIUM}) {
    bottom: 100px;
    left: 40%;
  }
`;

export function PressHeroBackground({ children, variant }) {
  return (
    <BackgroundBase
      baseBackground={theme => {
        switch (variant) {
          case 'enterprise':
          case 'enterpriseAccent':
            return theme.colorsNew.gray.black;
          default:
            return theme.colorsNew.gray.white;
        }
      }}
      renderBackground={() => (
        <>
          <CrossingLine />
          <LightSmallSquare />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
