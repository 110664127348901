import React from 'react';
import { Layout } from '../layout/Layout';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { PressHeroSection } from '../pageSections/prensa/PressHeroSection';
import { PressKitSection } from '../pageSections/prensa/PressKitSection';
import { PressKeyMessagesSection } from '../pageSections/prensa/PressKeyMessagesSection';
import { PressAppearancesSection } from '../pageSections/prensa/PressAppearancesSection';

const sectionMapping = {
  Strapi_ComponentPageSectionsHeroSection: PressHeroSection,
  Strapi_ComponentPageSectionsPressKitSection: PressKitSection,
  Strapi_ComponentPageSectionsKeyMessagesSection: PressKeyMessagesSection,
  Strapi_ComponentPageSectionsMediaAppearancesSection: PressAppearancesSection,
};

export default ({ data }) => {
  const { seoInfo, sections } = data.strapi.pressPage;
  return (
    <Layout>
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section mapping={sectionMapping} key={`${s.type}${s.id}`} {...s} />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query {
    strapi {
      pressPage {
        seoInfo {
          pageTitle
          pageDescription
          keywords
        }
        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsHeroSection {
            id
            title
            subtitle
            description
            image {
              ...Image
            }
            primaryCTA {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsPressKitSection {
            id
            title
            description
            cta {
              ...CTA
            }
            image {
              ...Image
            }
          }

          ... on Strapi_ComponentPageSectionsKeyMessagesSection {
            id
            title
            cta {
              ...CTA
            }
            keyMessages {
              id
              name
              text
            }
          }

          ... on Strapi_ComponentPageSectionsMediaAppearancesSection {
            id
            title
            mediaAppearances {
              appearanceUrl
              image {
                ...Image
              }
            }
          }
        }
      }
    }
  }
`;
