import React from 'react';
import styled from 'styled-components';

function ArrowRight(props) {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 0.851196L9.59 2.2612L15.17 7.8512H0V9.8512H15.17L9.59 15.4412L11 16.8512L19 8.8512L11 0.851196Z"
        fill="currentColor"
      />
    </svg>
  );
}

const CTAAnchor = styled.a`
  ${({ theme }) => theme.typography.H3}
  line-height: 19px;
  margin-top: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colorsNew.product.regular};
  display: inline-flex;
  align-items: center;
`;

export function PressCTA({ text, href, ...rest }) {
  return (
    <CTAAnchor href={href} {...rest}>
      {text} <ArrowRight style={{ marginLeft: '16px' }} />
    </CTAAnchor>
  );
}
