import React from 'react';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';
import { PressHeroBackground } from '../../backgrounds/PressHeroBackground';
import { Container } from '../../components/Container';
import { Grid } from '../../components/Grid';
import { MEDIUM, SMALL } from '../../breakpoints';
import { DisplayTitle } from '../../components/DisplayTitle';
import { CTAButton } from '../../components/CTAButton';
import { ResponsiveImage } from '../../components/ResponsiveImage';

const HeroContainer = styled(Container)`
  padding-top: 36px;
  @media (min-width: ${MEDIUM}) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
`;

const ImageWrapper = styled.div`
  width: 90vw;
  margin-right: auto;
  margin-top: 32px;
  pointer-events: none;

  picture,
  img {
    height: 100%;
  }

  @media (min-width: ${MEDIUM}) {
    position: absolute;
    margin-top: 80px;
    top: -64px;
    height: calc(100% - 40px);
    bottom: 0;
    right: 120px;
    width: auto;
    display: flex;
    align-items: end;

    img {
      width: 100%;
      max-width: 800px;
      object-fit: contain;
      object-position: right;
    }
  }
`;

const Title = styled(DisplayTitle)`
  color: ${({ theme }) => theme.colorsNew.increase.regular};
  margin-bottom: 48px;
`;

const TextContainer = styled.div`
  grid-column: span 2 / span 2;
  @media (min-width: ${SMALL}) {
    grid-column: 1 / span 3;
  }
`;

export function PressHeroSection({ title, description, image, primaryCTA }) {
  const imageSharp = image.childImageSharp || image.imageSharp.childImageSharp;
  return (
    <PressHeroBackground>
      <HeroContainer>
        <Grid>
          <TextContainer>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <H3
              dangerouslySetInnerHTML={{ __html: description }}
              weight="normal"
              style={{ marginBottom: '32px' }}
            />
            {primaryCTA && <CTAButton {...primaryCTA} />}
          </TextContainer>
        </Grid>
      </HeroContainer>
      <ImageWrapper>
        {imageSharp && <ResponsiveImage {...imageSharp.fluid} />}
      </ImageWrapper>
    </PressHeroBackground>
  );
}
