import React from 'react';
import styled from 'styled-components';

import { Container } from '../../components/Container';
import { SuperHeader, H2 } from '@increasecard/typed-components';
import { PressCTA } from './PressCTA';
import { Grid } from '../../components/Grid';
import { MEDIUM, SMALL } from '../../breakpoints';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colorsNew.increase.dark};
  color: ${({ theme }) => theme.colorsNew.gray.white};
  padding: 88px 0;
`;

const Title = styled(SuperHeader)`
  margin-bottom: 32px;
  @media (min-width: ${SMALL}) {
    margin-bottom: 0;
    margin-right: 32px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  @media (min-width: ${SMALL}) {
    flex-direction: row;
  }
`;

const CTA = styled(PressCTA)`
  color: ${({ theme }) => theme.colorsNew.product.accent};
`;

const Message = styled.div`
  margin-top: 48px;
  max-width: 20em;

  :first-child {
    margin-top: 0;
  }

  @media (min-width: ${MEDIUM}) {
    margin-top: 0;
    :nth-child(3n-2) {
      grid-column-start: 1;
    }
    :nth-child(3n-1) {
      grid-column-start: 4;
    }
    :nth-child(3n) {
      grid-column-start: 7;
    }
  }
`;
const MessageTitle = styled(H2)`
  text-transform: uppercase;
  padding-bottom: 15px;
  border-bottom: 2px solid ${({ theme }) => theme.colorsNew.product.accent};
  margin-bottom: 15px;
`;
const MessageText = styled.div``;

export function PressKeyMessagesSection({ title, cta, keyMessages }) {
  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>{title}</Title>
          <CTA {...cta} />
        </TitleWrapper>
        <Grid>
          {keyMessages.map(({ name, text }) => (
            <Message
              className="grid-span-lg-2 grid-span-md-8 grid-span-sm-2"
              key={name}
            >
              <MessageTitle>{name}</MessageTitle>
              <MessageText>{text}</MessageText>
            </Message>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
}
