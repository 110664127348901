import React from 'react';
import styled from 'styled-components';

import { Container } from '../../components/Container';
import { SuperHeader, H3 } from '@increasecard/typed-components';
import { Grid } from '../../components/Grid';
import GatsbyImage from 'gatsby-image';
import { PressCTA } from './PressCTA';
import { MEDIUM } from '../../breakpoints';

const Wrapper = styled.div`
  padding: 80px 0;
`;
const ImageWrapper = styled.div`
  padding: 16px 64px 16px 0;
  img {
    width: 100%;
  }

  @media (min-width: ${MEDIUM}) {
    padding: 42px;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(SuperHeader)`
  margin-bottom: 8px;
`;

export function PressKitSection({ title, description, cta, image }) {
  return (
    <Wrapper>
      <Container>
        <Grid style={{ alignItems: 'center' }}>
          <ImageWrapper className="grid-span-md-2 grid-span-sm-1">
            <GatsbyImage {...image.imageSharp.childImageSharp} />
          </ImageWrapper>
          <TextWrapper className="grid-span-md-3 grid-span-sm-2">
            <Title>{title}</Title>
            <H3 weight="normal">{description}</H3>
            <PressCTA {...cta} />
          </TextWrapper>
        </Grid>
      </Container>
    </Wrapper>
  );
}
