import React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

import { Container } from '../../components/Container';
import { SuperHeader } from '@increasecard/typed-components';
import { Grid as BaseGrid } from '../../components/Grid';
import { MEDIUM } from '../../breakpoints';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';

const Wrapper = styled.div`
  padding: 120px 0;
  position: relative;
`;

const TitleWrapper = styled.div`
  grid-area: title;
  padding-left: 40px;
  margin-bottom: 32px;
  @media (min-width: ${MEDIUM}) {
    margin-bottom: 0;
    grid-column: 6 / span 3;
  }
`;

const Grid = styled(BaseGrid)`
  align-items: start;
  justify-items: center;
  grid-template-areas:
    'title title'
    'images images';
  @media (min-width: ${MEDIUM}) {
    grid-template-areas: 'images images images images images title title title';
  }
`;

const Appearances = styled.div`
  display: grid;
  grid-area: images;
  grid-gap: 64px;
  align-items: center;
  justify-content: center;
  grid-template-columns: 200px;
  grid-template-rows: repeat(4, 80px);
  width: 100%;

  @media (min-width: ${MEDIUM}) {
    grid-template-columns: repeat(2, 196px);
    grid-template-rows: repeat(2, 80px);
  }
`;

const SquareOutline = styled(RoundedSquare).attrs({
  size: '238px',
  rotation: '-30deg',
})`
  top: calc(200px - 238px / 2);
  right: 16px;
  border: 1.5px solid ${({ theme }) => theme.colorsNew.increase.light50};
  position: absolute;
  clip-path: polygon(
    101.26% -5.88%,
    48.32% -5.97%,
    -3.53% 72.66%,
    0% 100%,
    100% 100%,
    100% 0%
  );

  @media (min-width: ${MEDIUM}) {
    top: calc(45% - 238px / 2);
    right: 17%;
  }
`;

export function PressAppearancesSection({ title, mediaAppearances }) {
  return (
    <Wrapper>
      <SquareOutline />
      <Container>
        <Grid>
          <Appearances className="grid-span-md-5 grid-span-sm-2">
            {mediaAppearances.map(({ appearanceUrl, image }) => (
              <a href={appearanceUrl} key={appearanceUrl}>
                <GatsbyImage {...image.imageSharp.childImageSharp} />
              </a>
            ))}
          </Appearances>
          <TitleWrapper className="grid-span-md-3 grid-span-sm-2">
            <SuperHeader dangerouslySetInnerHTML={{ __html: title }} />
          </TitleWrapper>
        </Grid>
      </Container>
    </Wrapper>
  );
}
